<template>
    <AppContainer>
        <add-service/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import AddService from '@/components/dashboard/services/AddService.vue'
export default {
  name: 'AddNewService',
  components: {
    AppContainer,
    AddService
  }
}
</script>